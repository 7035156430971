@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: black;
  color: white;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 300px;
  margin-bottom: 60px;
}

.token-label {
  color: white;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
}

.token-input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 2px solid white;
  background-color: black;
  color: white;
  padding: 0 10px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.token-input::placeholder {
  color: lightgray;
}

.token-input:focus {
  border-color: lightgray;
  background-color: #333;
}

.round-button {
  width: 80%;
  max-width: 300px;
  height: 80%;
  max-height: 300px;
  border-radius: 50%;
  background-color: white;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 2;
  margin-top: 20px; /* Space between the button and the input */
}

.round-button:hover {
  background-color: lightgray;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.round-button.lighting {
  animation: wave-edge 10s linear infinite;
}

.round-button:focus {
  outline: none;
}

.round-button:disabled {
  cursor: not-allowed;
}

.note {
  position: absolute;
  bottom: 20px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 1;
}

/* New animation for wavy edge */
@keyframes wave-edge {
  0% {
    border-radius: 50%;
  }
  10% {
    border-radius: 60% 40% 60% 40% / 40% 40% 60% 60%;
  }
  20% {
    border-radius: 40% 60% 60% 40% / 40% 60% 60% 40%;
  }
  30% {
    border-radius: 40% 60% 40% 60% / 60% 40% 60% 40%;
  }
  40% {
    border-radius: 60% 40% 40% 60% / 60% 60% 40% 40%;
  }
  50% {
    border-radius: 60% 40% 60% 40% / 40% 60% 40% 60%;
  }
  60% {
    border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
  }
  70% {
    border-radius: 40% 60% 40% 60% / 60% 40% 60% 40%;
  }
  80% {
    border-radius: 60% 40% 40% 60% / 60% 60% 40% 40%;
  }
  100% {
    border-radius: 60% 40% 60% 40% / 40% 60% 40% 60%;
  }
}

.wave {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  transform: translate(-50%, -50%);
  animation: wave-animation 5s ease-in-out infinite;
}

@keyframes wave-animation {
  0%, 100% {
    border-radius: 50%;
  }
  25% {
    border-radius: 30% 70% 30% 70% / 70% 30% 70% 30%;
  }
  50% {
    border-radius: 50% 50% 70% 30% / 30% 50% 50% 70%;
  }
  75% {
    border-radius: 70% 30% 50% 50% / 50% 70% 30% 50%;
  }
}
